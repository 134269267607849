<template>
  <v-row>
    <v-col cols="12" class="pb-3">
      <v-autocomplete
        placeholder="Tỉnh/Thành phố"
        v-model="c_params.state"
        :items="states.items"
        :loading="states.loading"
        :disabled="states.loading"
        item-text="nameWithType"
        item-value="id"
        return-object
        clearable
        hide-details
        solo
        flat
        dense
        height="44"
        :menu-props="{ zIndex: 99 }"
        @input="onChangeProvince"
        no-data-text="Không kết quả thỏa mãn"
        background-color="#E8ECF8"
        @click:clear="
          c_params.state = null;
          c_params.district = null;
          c_params.commune = null;
        "
      >
        <template #prepend-inner>
          <v-icon small color="#9aa9bc">mdi-city</v-icon>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="12" class="pt-0 pb-3">
      <v-autocomplete
        placeholder="Quận/Huyện"
        v-model="c_params.district"
        :items="districts.items"
        :loading="districts.loading"
        :disabled="districts.loading || states.loading || !c_params.state"
        item-text="nameWithType"
        item-value="id"
        return-object
        hide-details
        clearable
        solo
        flat
        dense
        height="44"
        @input="onChangeDistrict"
        :menu-props="{ zIndex: 99 }"
        no-data-text="Không kết quả thỏa mãn"
        background-color="#E8ECF8"
        @click:clear="
          c_params.district = null;
          c_params.commune = null;
        "
      >
        <template #prepend-inner>
          <v-icon small color="#9aa9bc">mdi-bank</v-icon>
        </template>
      </v-autocomplete>
    </v-col>
    <!-- <v-col cols="12" class="pt-0 pb-3">
      <v-autocomplete
        placeholder="Xã/Phường/Thị Trấn"
        v-model="c_params.commune"
        :items="communes.items"
        :loading="communes.loading"
        :disabled="communes.loading || districts.loading || !c_params.district"
        item-text="nameWithType"
        item-value="id"
        @change="$emit('click:search')"
        @click:clear="
          c_params.commune = null;
          $emit('click:search');
        "
        return-object
        hide-details
        clearable
        solo
        flat
        dense
        height="44"
        :menu-props="{ zIndex: 99 }"
        no-data-text="Không kết quả thỏa mãn"
        background-color="#E8ECF8"
      >
        <template #prepend-inner>
          <svg
            width="14"
            height="13"
            viewBox="0 0 14 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.99994 2.91891L1.68846 7.81295C1.64909 7.85002 1.59877 7.86729 1.55551 7.89801V12.5938C1.55551 12.8182 1.72954 13 1.9444 13H5.57151L4.66661 11.1513L7.19706 9.52631L5.73484 6.49924L9.33327 9.97217L6.80282 11.5972L7.7731 13H12.0555C12.2703 13 12.4444 12.8182 12.4444 12.5938V7.89902C12.4031 7.86957 12.354 7.85281 12.3165 7.81777L6.99994 2.91891ZM13.8709 5.9993L12.4444 4.6833V1.21875C12.4444 0.994297 12.2703 0.812501 12.0555 0.812501H10.4999C10.2851 0.812501 10.111 0.994297 10.111 1.21875V2.53119L7.65011 0.261778C7.46466 0.0875982 7.2323 0.000254461 6.99994 5.55188e-07C6.76758 -0.000253351 6.53571 0.0865826 6.35098 0.260762L0.12902 5.9993C-0.0306675 6.14936 -0.0440355 6.40606 0.09961 6.57313L0.619748 7.17793C0.76315 7.34475 1.00912 7.35871 1.16905 7.20865L6.74279 2.07264C6.88984 1.93731 7.11029 1.93731 7.25734 2.07264L12.8311 7.2084C12.9908 7.3582 13.2367 7.34449 13.3804 7.17768L13.9005 6.57287C14.0439 6.40606 14.0308 6.1491 13.8709 5.9993Z"
            />
          </svg>
        </template>
      </v-autocomplete>
    </v-col> -->
    <v-col cols="12">
      <v-btn block depressed color="primary" @click="onSearch()">
        Tìm kiếm
      </v-btn>
    </v-col>
    <slot />
  </v-row>
</template>

<script>
import { API_COMMUNES, API_DISTRICTS, API_STATE } from "@/service/api";
export default {
  props: { params: { type: Object, default: () => ({}) } },
  data: () => ({
    states: { loading: false, items: [] },
    communes: { loading: false, items: [] },
    districts: { loading: false, items: [] },
    c_params: {},
  }),
  created() {
    this.onInitData();
  },
  computed: {
    // c_params: {
    //   get() {
    //     return this.params;
    //   },
    //   set(value) {
    //     this.$emit("update:params", value);
    //   },
    // },
  },
  watch: {
    params: {
      handler(value) {
        this.c_params = value;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onInitData() {
      this.states.loading = true;
      API_STATE()
        .then((res) => {
          this.states.items = res;
        })
        .finally(() => {
          this.states.loading = false;
        });
      if (this.params.state && this.params.state.code) {
        this.districts.loading = true;
        API_DISTRICTS(this.params.state.code)
          .then((res) => {
            this.districts.items = res;
          })
          .finally(() => {
            this.districts.loading = false;
          });
      }
      if (this.params.district && this.params.district.code) {
        this.communes.loading = true;
        API_COMMUNES(this.params.district.code)
          .then((res) => {
            this.communes.items = res;
          })
          .finally(() => {
            this.communes.loading = false;
          });
      }
    },
    onChangeProvince() {
      if (!this.params.state) return;
      this.params.district = null;
      this.districts.loading = true;
      API_DISTRICTS(this.params.state.code)
        .then((res) => {
          this.districts.items = res;
        })
        .finally(() => {
          this.districts.loading = false;
        });
    },
    onChangeDistrict() {
      if (!this.params.district) return;
      this.params.commune = null;
      this.communes.loading = true;
      API_COMMUNES(this.params.district.code)
        .then((res) => {
          this.communes.items = res;
        })
        .finally(() => {
          this.communes.loading = false;
        });
    },
    onSearch() {
      this.$emit("update:params", this.c_params);
      this.$emit("click:search");
    },
  },
};
</script>

<style scoped>
.theme--light svg {
  fill: #9aa9bc;
}
</style>
